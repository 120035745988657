<template>
  <Upload
    action=""
    type="drag"
    :show-upload-list="false"
    :before-upload="handleBeforeUpload"
    class="logo-upload"
    v-bind="$attrs"
  >
    <template v-if="value">
      <div class="preview-wrap">
        <template v-if="image">
          <img class="image-preview" :src="imgUrl" alt="Image Url"/>
        </template>
        <template v-else>
          <div class="filename-preview">{{ imgUrl }}</div>
        </template>
      </div>
    </template>
    <template v-else>
      <img
        src="@/assets/image/ic_add_project@2x.png"
        width="24"
        height="24"
        alt="Add LOGO"
      />
      <div>点击或拖拽</div>
    </template>
  </Upload>
</template>

<script>
import toolFunctions from '@/utils/toolFunctions';

export default {
  name: 'FileInput',
  props: {
    value: {
      type: [File, String, Object],
    },
    image: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imgUrl: '',
    };
  },
  computed: {
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          if (val instanceof File) {
            // 上传文件时，显示预览或文件名
            this.imgUrl = this.image ? toolFunctions.imageFileToURL(val) : val.name;
          } else {
            // 已上传的文件，显示预览或者重新上传
            this.imgUrl = this.image ? val : '重新上传';
          }
        } else {
          this.imgUrl = '';
        }
      },
    },
  },
  methods: {
    handleBeforeUpload(file) {
      const ext = file.name.split('.').pop().toLowerCase();
      const { format } = this.$attrs;
      if (format && format.length && !format.includes(ext)) {
        this.$Message.warning(`请上传${format.join(', ')}格式的文件`);
        return false;
      }
      this.$emit('input', file);
      // 触发FormItem的验证
      this.$parent.$emit('on-form-change', file);
      return false;
    },
  },
};
</script>

<style lang="less" scoped>
@fileBoxWidth: 96px;
@fileBoxHeight: @fileBoxWidth;
@fileBoxBorder: 1px dashed #d5d7e6;
@fileBoxBgColor: #fafbff;
@fileBoxRadius: 8px;
.logo-upload {
  display: flex;
  width: 100%;
  /deep/ .ivu-upload-drag {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: @fileBoxWidth;
    height: @fileBoxHeight;
    border-radius: @fileBoxRadius;

    background-color: @fileBoxBgColor;
    color: #bbbcc9;
    line-height: 20px;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }
  .preview-wrap{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-preview {
    max-width: 90%;
    max-height: 90%;
  }
  .filename-preview{
    padding: 10px;
    word-break: break-all;
  }
}
</style>
