<template>
  <div>
    <FormItem prop="app_info">
      <template #label>包名 (App Info)</template>
      <Input v-model="form.app_info" placeholder="请输入"/>
    </FormItem>
    <FormItem prop="dataset">
      <template #label>远端数据库名称 (Dataset)</template>
      <Input v-model="form.dataset" placeholder="请输入"/>
    </FormItem>
    <FormItem prop="analytic_table">
      <template #label>表名 (Analytic Table)</template>
      <Input v-model="form.analytic_table" placeholder="请输入"/>
    </FormItem>
    <FormItem prop="analysis_service">
      <template #label>授权文件 (Service Account)</template>
      <FileInput
        v-model="form.analysis_service"
        accept="application/json"
        :format="['json']"
      ></FileInput>
    </FormItem>
  </div>
</template>

<script>
import FileInput from '@/components/FileInput.vue';

export default {
  name: 'AnalysisFormItem',
  components: { FileInput },
  props: {
    form: {
      type: Object,
    },
  },
};
</script>

<style scoped>

</style>
