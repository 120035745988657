export default {
  data() {
    return {
      projectForm: {
        pid: '',
        project_name: '',
        project_logo: null,
      },
      analysisForm: {
        app_info: '',
        dataset: '',
        analytic_table: '',
        analysis_service: null,
      },
      analysisFormRules: {
        app_info: { required: true, message: 'App Info不能为空', trigger: 'change' },
        dataset: { required: true, message: 'Dataset不能为空', trigger: 'change' },
        analytic_table: { required: true, message: 'Analytic Table不能为空', trigger: 'change' },
        analysis_service: { required: true, message: 'Service Account不能为空' },
      },
      googlePlayForm: {
        package_name: '',
        bucket_name: '',
        google_play_service: null,
      },
      googlePlayFormRules: {
        package_name: { required: true, message: 'Package Name不能为空', trigger: 'change' },
        bucket_name: { required: true, message: 'Bucket Name不能为空', trigger: 'change' },
        google_play_service: { required: true, message: 'Service Account不能为空' },
      },
    };
  },
  computed: {
    projectNames() {
      return this.allProjectNames || this.projectList
        ?.filter((x) => x.project_id !== this.currentProjectId)
        ?.map((x) => x.project_name);
    },
    projectFormRules() {
      return {
        pid: { required: true, message: '项目ID不能为空', trigger: 'change' },
        project_name: [
          { required: true, message: '项目名称不能为空', trigger: 'change' },
          {
            asyncValidator: (rule, value) => new Promise((resolve, reject) => {
              if (this.projectNames.includes(value)) {
                reject(new Error('项目名称已存在'));
              } else {
                resolve();
              }
            }),
          },
        ],
      };
    },
  },
};
