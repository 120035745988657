<template>
  <div>
    <FormItem prop="package_name">
      <template #label>包名 (Package Name)</template>
      <Input v-model="form.package_name" placeholder="请输入"/>
    </FormItem>
    <FormItem prop="bucket_name">
      <template #label>数据桶名称 (Bucket Name)</template>
      <Input v-model="form.bucket_name" placeholder="请输入"/>
    </FormItem>
    <FormItem prop="google_play_service">
      <template #label>授权文件 (Service Account)</template>
      <FileInput
        v-model="form.google_play_service"
        accept="application/json"
        :format="['json']"
      ></FileInput>
    </FormItem>
  </div>
</template>

<script>
import FileInput from '@/components/FileInput.vue';

export default {
  name: 'GooglePlayFormItem',
  components: { FileInput },
  props: {
    form: {
      type: Object,
    },
  },
};
</script>

<style scoped>

</style>
