<template>
  <div class="project-list-wrap">
    <div class="header">
      <h1 class="header-title">项目列表</h1>
      <div class="header-operation">
        <Button class="header-button" type="primary" @click="handleAdd">
          <img src="@/assets/image/ic_add.png" alt="Add" />
          <span>添加项目</span>
        </Button>
      </div>
    </div>
    <div class="content" ref="project_list">
      <div v-if="!projectList" class="empty-list">
        <img class="empty-list-img"
             src="@/assets/image/pic_home_empty@2x.png" alt="Empty list" />
        <h1 class="empty-list-title">欢迎进入BI系统</h1>
        <p class="empty-list-description">您还没有添加项目，点击右上角的按钮添加项目~</p>
      </div>
      <div v-else class="project-list">
        <div class="table-search">
          <Input v-model="searchContent" search placeholder="项目名称/项目ID"
                 @on-search="fetchProjectList"></Input>
        </div>
        <div class="table-box">
          <Table :columns="columns" :data="projectList"></Table>
        </div>
      </div>
    </div>
    <ProjectConfigModal
      v-model="projectModalVisible"
      title="编辑项目"
      :form="projectForm"
      :rules="projectFormRules"
      is-edit
      @on-ok="handleProjectEditSubmit"
    >
      <template #form-item>
        <ProjectFormItem :form="projectForm" edit></ProjectFormItem>
      </template>
    </ProjectConfigModal>
    <ProjectConfigModal
      v-model="analysisModalVisible"
      :title="analysisModalTitle"
      :form="analysisForm"
      :rules="analysisFormRules"
      :is-edit="isAnalysisEdit"
      @on-ok="handleAnalysisConfigSubmit"
    >
      <template #form-item>
        <AnalysisFormItem :form="analysisForm"></AnalysisFormItem>
      </template>
    </ProjectConfigModal>
    <ProjectConfigModal
      v-model="googlePlayModalVisible"
      :title="googlePlayModalTitle"
      :form="googlePlayForm"
      :rules="googlePlayFormRules"
      :is-edit="isGooglePlayEdit"
      @on-ok="handleGooglePlayConfigSubmit"
    >
      <template #form-item>
        <GooglePlayFormItem :form="googlePlayForm"></GooglePlayFormItem>
      </template>
    </ProjectConfigModal>
    <ConfirmModal
      :modal_visible="deleteModalVisible"
      confirm_message="确定要删除所选项目吗？"
      @confirm="handleProjectDeleteSubmit"
      @cancel="deleteModalVisible=false">
    </ConfirmModal>
  </div>
</template>

<script>
import ProjectConfigModal from '@/views/home/content/config/ProjectConfigModal.vue';
import ConfirmModal from '@/components/modal/ConfirmModal.vue';
import ProjectFormItem from '@/views/home/content/config/ProjectFormItem.vue';
import AnalysisFormItem from '@/views/home/content/config/AnalysisFormItem.vue';
import GooglePlayFormItem from '@/views/home/content/config/GooglePlayFormItem.vue';
import formMixin from '@/views/home/content/config/formMixin';

const baseUrl = 'https://dc-bi-center.herokuapp.com';
export default {
  name: 'ProjectListConfig',
  components: {
    GooglePlayFormItem,
    AnalysisFormItem,
    ProjectFormItem,
    ProjectConfigModal,
    ConfirmModal,
  },
  mixins: [formMixin],
  data() {
    return {
      projectList: null,
      columns: [
        {
          key: 'project_id',
          title: '项目ID',
          minWidth: 100,
          // maxWidth: 150,
        },
        {
          key: 'project_name',
          title: '项目名称',
          minWidth: 250,
          render: (h, { row }) => h('div', { class: 'table-cell' }, [
            h('img', {
              attrs: { src: `${baseUrl}/${row.project_logo}` },
              class: 'table-cell-project-logo',
            }),
            h('span', {
              class: 'table-cell-project-name',
              on: { click: this.handleEnterProject.bind(this, row) },
            }, row.project_name),
          ]),
        },
        {
          key: 'created_time',
          title: '创建时间',
          minWidth: 120,
        },
        {
          key: 'analysis',
          title: 'Analysis',
          minWidth: 120,
          render: (h, { row }) => h('div', { class: 'table-cell' }, [
            h('div', { class: `table-cell-dot table-cell-dot-${row.analysis_config ? 'success' : 'error'}` }),
            h(
              'a',
              {
                class: row.analysis_config ? '' : '',
                on: { click: this.handleAnalysisConfig.bind(this, row) },
              },
              row.analysis_config ? '已配置' : '未配置',
            ),
          ]),
        },
        {
          key: 'googlePlay',
          title: 'GooglePlay',
          minWidth: 120,
          render: (h, { row }) => h('div', { class: 'table-cell' }, [
            h('div', { class: `table-cell-dot table-cell-dot-${row.google_play_config ? 'success' : 'error'}` }),
            h(
              'a',
              {
                class: row.google_play_config ? '' : '',
                on: { click: this.handleGooglePlayConfig.bind(this, row) },
              },
              row.google_play_config ? '已配置' : '未配置',
            ),
          ]),
        },
        {
          title: '操作',
          minWidth: 120,
          align: 'center',
          render: (h, { row }) => h('div', [
            h('a', { on: { click: this.handleProjectEdit.bind(this, row) } }, '编辑'),
            h('divider', { props: { type: 'vertical' } }),
            h(
              'a',
              {
                class: 'error',
                on: { click: this.handleProjectDelete.bind(this, row) },
              },
              '删除',
            ),
          ]),
        },
      ],
      searchContent: '',
      currentProjectId: '',
      /*  项目基本信息配置部分 */
      deleteModalVisible: false,
      projectModalVisible: false,
      isEdit: true,

      /*  Analysis配置部分 */
      analysisModalVisible: false,
      isAnalysisEdit: false,

      /*  GooglePlay配置部分 */
      googlePlayModalVisible: false,
      isGooglePlayEdit: false,
    };
  },
  computed: {
    analysisModalTitle() {
      return this.isAnalysisEdit ? '编辑Analysis配置' : '添加Analysis配置';
    },
    googlePlayModalTitle() {
      return this.isGooglePlayEdit ? '编辑GooglePlay配置' : '添加GooglePlay配置';
    },
  },
  mounted() {
    this.fetchProjectList();
  },
  methods: {
    // 获取项目列表
    async fetchProjectList() {
      const loading = this.$loading(this.$refs.project_list);
      try {
        loading.show();
        const params = {};
        if (this.searchContent) {
          params.search_content = this.searchContent;
        }
        const { data } = await this.$api.getConfigProjects({ params });
        this.projectList = data.projects;
        if (!this.searchContent && data.projects.length === 0) {
          this.projectList = null;
        }
        console.log(data);
      } catch (error) {
        this.$Message.error(error.message);
      } finally {
        loading.hide();
      }
    },
    handleAdd() {
      localStorage.setItem('projectNames', this.projectList.map((x) => x.project_name).join(','));
      this.$router.push({ name: 'projectCreate' });
    },
    handleEnterProject(row) {
      this.$router.push(`/${row.project_id}/overview`);
    },
    handleProjectEdit(row) {
      this.currentProjectId = row.project_id;
      this.projectForm = {
        pid: row.project_id,
        project_name: row.project_name,
        project_logo: row.project_logo ? `${baseUrl}/${row.project_logo}` : '',
      };
      this.projectModalVisible = true;
    },
    handleProjectDelete(row) {
      this.currentProjectId = row.project_id;
      this.deleteModalVisible = true;
    },
    handleAnalysisConfig(row) {
      this.currentProjectId = row.project_id;
      const config = row.analysis_config;
      if (config) {
        this.isAnalysisEdit = true;
        this.analysisForm = { ...config, analysis_service: {} };
        this.currentProjectId = row.project_id;
      } else {
        this.isAnalysisEdit = false;
      }
      this.analysisModalVisible = true;
    },
    handleGooglePlayConfig(row) {
      this.currentProjectId = row.project_id;
      const config = row.google_play_config;
      if (config) {
        this.isGooglePlayEdit = true;
        this.googlePlayForm = { ...config, google_play_service: {} };
      } else {
        this.isGooglePlayEdit = false;
      }
      this.googlePlayModalVisible = true;
    },
    handleAnalysisConfigSubmit(cb) {
      const formData = new FormData();
      Object.keys(this.analysisForm).forEach((key) => {
        if (this.analysisForm[key]) {
          formData.append(key, this.analysisForm[key]);
        }
      });
      this.$api.updateAnalysisConfig(this.currentProjectId, formData).then(({ data }) => {
        if (data.result === 'success') {
          this.$Message.success('Analysis配置成功');
          this.fetchProjectList();
          this.analysisModalVisible = false;
        } else {
          this.$Message.error('Analysis配置失败');
        }
      }).catch((error) => {
        this.$Message.error('Analysis配置失败');
        console.log(error);
      }).finally(() => {
        cb();
      });
    },
    handleGooglePlayConfigSubmit(cb) {
      const formData = new FormData();
      Object.keys(this.googlePlayForm).forEach((key) => {
        if (this.googlePlayForm[key]) {
          formData.append(key, this.googlePlayForm[key]);
        }
      });
      this.$api.updateGooglePlayConfig(this.currentProjectId, formData).then(({ data }) => {
        if (data.result === 'success') {
          this.$Message.success('GooglePlay配置成功');
          this.fetchProjectList();
          this.googlePlayModalVisible = false;
        } else {
          this.$Message.error('GooglePlay配置失败');
        }
      }).catch((error) => {
        this.$Message.error('GooglePlay配置失败');
        console.log(error);
      }).finally(() => {
        cb();
      });
    },
    handleProjectEditSubmit(cb) {
      const formData = new FormData();
      formData.append('project_name', this.projectForm.project_name);
      if (this.projectForm.project_logo && this.projectForm.project_logo instanceof File) {
        formData.append('project_logo', this.projectForm.project_logo);
      }
      this.$api.modifyProject(this.currentProjectId, formData).then(({ data }) => {
        if (data.result === 'success') {
          this.$Message.success('项目修改成功');
          this.fetchProjectList();
          this.projectModalVisible = false;
        } else {
          this.$Message.error('项目修改失败');
        }
      }).catch((error) => {
        this.$Message.error('项目修改失败');
        console.log(error);
      }).finally(() => {
        cb();
      });
    },
    handleProjectDeleteSubmit() {
      this.$api.deleteProject(this.currentProjectId).then(({ data }) => {
        if (data.result === 'success') {
          this.$Message.success('项目删除成功');
          this.fetchProjectList();
        } else {
          this.$Message.error('项目删除失败');
        }
        this.deleteModalVisible = false;
      }).catch((error) => {
        this.$Message.error('项目删除失败');
        console.log(error);
      });
    },
  },
};
</script>

<style lang="less">
.table-cell{
  display: flex;
  align-items: center;
}
.table-cell-project-logo{
  width: 20px;
  margin-right: 10px;
}
.table-cell-project-name{
  cursor: pointer;
}
a{
  color: #4b5cf0;
  font-size: 14px;
  &.error{
    color: #ed4014;
    &:hover{
      color: #ff7552;
    }
  }
  &:hover{
    color: #728bfc;
  }
}
.table-cell-dot{
  margin-right: 8px;
  min-width: 6px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
.table-cell-dot-success {
  background: #19be6b;
}
.table-cell-dot-error {
  background: #ed4014;
}
</style>
<style scoped lang="less">
.title {
  font-size: 24px;
  font-weight:600;
  color: #202444;
}
.project-list-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 24px;
  overflow: auto;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    line-height: 36px;
    .header-title {
      .title();
    }
    .header-operation {
      display: flex;
      flex-direction: row;
      align-items: center;
      .header-button {
        height: 36px;
        img {
          width: 16px;
          margin-right: 4px;
        }
        img,
        span {
          vertical-align: middle;
        }
      }
    }
  }
  .content{
    position: relative;
    min-height: calc(~"100% - 60px");
    background: #fff;
    border-radius: 12px;
    .empty-list {
      position: absolute;
      background: #f2f3f7;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .empty-list-img {
        height: 400px;
        max-height: calc(~"100% - 90px");
      }
      .empty-list-title {
        .title();
        margin: 24px 0 8px;
      }
      .empty-list-description {
        font-size: 14px;
        color: #878aa2;
        line-height: 20px;
      }
    }
    .project-list{
      padding: 24px;
    }
    .table-search{
      width: 250px;
      margin-bottom:17px;
      /deep/ .ivu-input-icon{
        line-height: 36px;
      }
    }
  }
}
</style>
