<template>
  <div>
    <FormItem prop="pid" label="项目ID">
      <template v-if="edit">
        <Select v-model="form.pid" disabled transfer>
          <Option :value="form.pid || ''">{{ form.pid }}</Option>
        </Select>
      </template>
      <template v-else>
        <Select v-model="form.pid" placeholder="请选择" transfer>
            <Option v-for="item in projectIdList"
                    :key="item.project_id"
                    :value="item.project_id"
                    :label="`${item.project_id} (${item.project_name})`">
            </Option>
        </Select>
      </template>
    </FormItem>
    <FormItem prop="project_name" label="项目名称">
      <Input v-model="form.project_name" placeholder="请输入" />
    </FormItem>
    <FormItem prop="project_logo" label="项目LOGO">
      <FileInput
        v-model="form.project_logo"
        image
        accept="image/*"
        :format="['jpg','jpeg','png','svg','ico','gif','webp','bmp']"
      >
      </FileInput>
    </FormItem>
  </div>
</template>

<script>
import FileInput from '@/components/FileInput.vue';

export default {
  name: 'ProjectFormItem',
  components: { FileInput },
  props: {
    form: {
      type: Object,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      projectIdList: [],
    };
  },
  created() {
    this.fetchProjectIds();
  },
  methods: {
    // 获取项目ID集合
    async fetchProjectIds() {
      try {
        const { data } = await this.$api.getProjectIDList();
        this.projectIdList = data.project_id_list;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>

</style>
