<template>
  <Modal
    v-bind="$props"
    v-model="modalShow"
    :closable="false"
    :mask-closable="false"
    class="modal"
    @on-visible-change="handleVisibleChange">
    <div class="header" slot="header">
      <h1 class="header-title">{{ title }}</h1>
    </div>
    <div class="main">
      <Form ref="form" :model="form" :rules="rules" label-position="top" @submit.native.prevent>
        <slot name="form-item"></slot>
      </Form>
    </div>
    <div class="footer" slot="footer">
      <Button type="default" @click="modalShow = false">取消</Button>
      <Button type="primary" :loading="submitLoading" @click="handleOK">确认</Button>
    </div>
  </Modal>
</template>

<script>
import { Modal } from 'view-design';

export default {
  name: 'ProjectConfigModal',
  props: {
    // 继承的props
    ...Modal.props,
    //  重写继承的width prop
    width: {
      type: Number,
      default: 400,
    },
    // 自定义props
    form: {
      type: Object,
    },
    rules: {
      type: Object,
    },
    isEdit: {
      type: Boolean,
    },
  },
  data() {
    return {
      originalForm: null,
      submitLoading: false,
    };
  },
  computed: {
    modalShow: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit('input', newVal);
      },
    },
    isFormChanged() {
      // eslint-disable-next-line no-restricted-syntax
      for (const key of Object.keys(this.form)) {
        if (this.form[key] !== this.originalForm?.[key]) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    handleVisibleChange(value) {
      if (!value) {
        setTimeout(() => {
          this.$refs.form.resetFields();
        }, 200);
        this.originalForm = null;
      } else {
        this.originalForm = this.isEdit ? { ...this.form } : null;
      }
    },

    handleOK() {
      if (this.isFormChanged) {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            this.$emit('on-ok', () => {
              this.submitLoading = false;
            });
          }
        });
      } else {
        this.$Message.warning('没有更改');
        this.modalShow = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@paddingSide: 32px;
@headerVerticalPadding: 20px;
@headerPadding: @headerVerticalPadding @paddingSide;
@footerVerticalPadding: 24px;
@footerPadding: @footerVerticalPadding @paddingSide;
@inputTitleMarginBottom: 6px;
@inputTextMarginBottom: 25px;
@fileBoxWidth: 96px;
@fileBoxHeight: @fileBoxWidth;
@fileBoxBorder: 1px dashed #D5D7E6;
@fileBoxBgColor: #FAFBFF;
@fileBoxRadius: 8px;
@messageTextMarginBottom: 9px;
@footerBtnHeight: 36px;
.modal {
  .header {
    padding: @headerPadding;
    .header-title {
      font-size: 20px;
      font-weight: 600;
      color: #202444;
      line-height: 28px;
    }
  }
  .main{
    padding: 0 32px;
    /deep/ .ivu-form-item-label{
      font-size: 12px;
      font-weight: 400;
      color: #545778;
      line-height: 17px;
      padding-bottom: 6px;
    }
  }
  .footer {
    padding: @footerPadding;
    .footer-btn {
      height: @footerBtnHeight;
    }
  }
}
</style>
